export default [
  {
    id: 'c',
    category: 'Pizza',
    title: '5 Food Apps Delivering the Best of Your City',
    pointOfInterest: 200,
    backgroundColor: '#814A0E',
    p1: 'lorem',
  },
  // {
  //   id: 'f',
  //   category: 'How to',
  //   title: 'Arrange Your Apple Devices for the Gram',
  //   pointOfInterest: 120,
  //   backgroundColor: '#959684',
  // },
  // {
  //   id: 'a',
  //   category: 'Pedal Power',
  //   title: 'Map Apps for the Superior Mode of Transport',
  //   pointOfInterest: 260,
  //   backgroundColor: '#5DBCD2',
  // },
  // {
  //   id: 'g',
  //   category: 'Holidays',
  //   title: 'Our Pick of Apps to Help You Escape From Apps',
  //   pointOfInterest: 200,
  //   backgroundColor: '#8F986D',
  // },
  // {
  //   id: 'd',
  //   category: 'Photography',
  //   title: 'The Latest Ultra-Specific Photography Editing Apps',
  //   pointOfInterest: 150,
  //   backgroundColor: '#FA6779',
  // },
  // {
  //   id: 'h',
  //   category: "They're all the same",
  //   title: '100 Cupcake Apps for the Cupcake Connoisseur',
  //   pointOfInterest: 60,
  //   backgroundColor: '#282F49',
  // },
  // {
  //   id: 'e',
  //   category: 'Cats',
  //   title: 'Yes, They Are Sociopaths',
  //   pointOfInterest: 200,
  //   backgroundColor: '#AC7441',
  // },
  // {
  //   id: 'b',
  //   category: 'Holidays',
  //   title: 'Seriously the Only Escape is the Stratosphere',
  //   pointOfInterest: 260,
  //   backgroundColor: '#CC555B',
  // },
]
